import styled from '@emotion/styled';
import { colors } from '../utilities/themeVars';
import React from 'react';

const ButtonGrid = styled(props => <button {...props} />)`
  width: 92px;
  padding: 8px 0;
  background: ${props => (props.active ? colors.primary : 'transparent')};
  color: ${props => (props.active ? colors.white : colors.primary)};
  text-transform: uppercase;
  border-right: solid 2px ${colors.primary};
  font-weight: bold;
  letter-spacing: ${props =>
    props.letterSpacing ? props.letterSpacing : `1px`};
  outline: none;

  &:last-child {
    border-right: none;
  }
`;

export default ButtonGrid;
