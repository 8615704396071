import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import HeadingWithSub from "../components/HeadingWithSub";
import { StoreContext } from "../store";
import GalleryContainer from "../components/GalleryContainer";
import SearchableContainer from "../components/SearchableContainer";
import TertiaryGridToggle from "../components/TertiaryGridToggle";
import CollectionContainer from "../components/CollectionContainer";
import ParentRouteHandler from "../components/ParentRouteHandler";

const CollectionPage = ({ data, location }) => {
  const { collectionsViewState, setCollectionsViewState } = useContext(
    StoreContext
  );

  const shouldSelectFunFacts = location.search.includes("funfacts=1");
  const isNavigatedFromNavMenu = location.search.includes("from-nav=1");

  /**
   * react-search-input cant find artist name as node.rel.artist[0].artist_name,
   * so adding it as top level key/value
   */
  const dataWithArtistName = data.allNodeArtPages.edges.map(({ node }) => {
    return {
      node: {
        ...node,
        artistName: node.rel.artist[0].artist_name,
      },
    };
  });

  const renderViewMode = (artworks, renderListCb, dataForGalleryCb) => {
    if (collectionsViewState === "list") {
      return renderListCb(artworks);
    } else if (collectionsViewState === "grid") {
      return <GalleryContainer data={dataForGalleryCb(artworks)} />;
    }

    // get only nodes with the fun facts
    const content = artworks.filter((art) => {
      const { node } = art;
      return node.field_fun_facts && node.field_fun_facts.length;
    });

    return <GalleryContainer data={dataForGalleryCb(content)} />;
  };

  useEffect(() => {
    if (shouldSelectFunFacts) {
      setCollectionsViewState("fun facts");
    }
  }, []);

  return (
    <CollectionContainer
      render={({ renderList, dataForGalleryView }) => {
        return (
          <>
            {isNavigatedFromNavMenu && <ParentRouteHandler title={"back"} />}
            <Seo title="The Collection" />
            <div className="heading">
              <HeadingWithSub main={`The Collection`} subTitle={`On view`} />
            </div>

            <div className={`toggle-wrap`}>
              <TertiaryGridToggle
                viewState={collectionsViewState}
                handler={setCollectionsViewState}
              />
            </div>
            <SearchableContainer
              searchableContent={dataWithArtistName}
              fieldsToSearch={["node.title", "node.artistName"]}
              render={(art) =>
                renderViewMode(art, renderList, dataForGalleryView)
              }
            />
          </>
        );
      }}
    />
  );
};

export default CollectionPage;

export const query = graphql`
  query {
    allNodeArtPages(
      filter: { field_on_view: { eq: true } }
      sort: { fields: [field_sort_order_weight], order: ASC }
    ) {
      edges {
        node {
          ...ArtPages
        }
      }
    }
  }
`;
