import React from "react";
import styled from "@emotion/styled";
import { colors } from "../utilities/themeVars";
import PropTypes from "prop-types";
import ButtonGrid from "./ButtonGrid";

const Container = styled.div`
  display: inline-block;
  border: solid 2px ${colors.primary};
`;

/**
 * @param viewState - should be a value that matches to an element in <options> - the "active" button
 * @param handler -
 * @param options - the options to display
 * @constructor
 */
const TertiaryGridToggle = ({
  viewState,
  handler,
  options = ["List", "Grid", "Fun Facts"],
}) => {
  return (
    <div style={{ textAlign: "center" }}>
      <Container>
        {options.map((option, index) => (
          <ButtonGrid
            key={option}
            active={viewState.toLowerCase() === option.toLowerCase() ? 1 : 0}
            letterSpacing={index === 2 ? "0.6px" : "1px"}
            onClick={() => handler(option.toLowerCase())}
          >
            {option}
          </ButtonGrid>
        ))}
      </Container>
    </div>
  );
};

TertiaryGridToggle.propTypes = {
  viewState: PropTypes.string,
  handler: PropTypes.func,
  options: PropTypes.array,
};

export default TertiaryGridToggle;
