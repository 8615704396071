import React from 'react';
import ReactDOM from 'react-dom';
import SearchInput, { createFilter } from 'react-search-input';
import styled from '@emotion/styled';
import { colors } from '../utilities/themeVars';
import { StoreContext } from '../store';
import searchIcon from '../images/icon-search.svg';

const SearchInputStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  // search-input div wrapper
  .search-input {
    position: relative;
    padding: 0 10px;
    width: 100%;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 14px;
      z-index: 2;
      height: 14px;
      font-size: 20px;
      bottom: 6px;
      left: 10px;
      line-height: 32px;
      background: url(${searchIcon}) no-repeat;
      background-size: cover;
    }
  }

  input {
    width: 100%;
    border: none;
    border-bottom: solid 1px ${colors.gray1};
    font-size: 20px;
    background: transparent;
    outline: none;
    padding-left: 20px;

    &::placeholder {
      opacity: 0.3;
    }
  }

  .clear-button {
    position: absolute;
    right: 10px;
    font-size: 25px;
    visibility: ${props =>
      props.searchTerm.length === 0 ? 'hidden' : 'visible'};
    opacity: 0.4;
  }
`;

class SearchableContainer extends React.Component {
  static contextType = StoreContext;
  focusEnabled = false;

  constructor(props) {
    super(props);
    this.searchUpdated = this.searchUpdated.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.scrollToPoint = this.scrollToPoint.bind(this);
  }

  searchUpdated(term) {
    this.context.searchCollections(term);
  }

  clearSearch() {
    this.context.searchCollections('');
  }

  componentDidMount() {
    this.handleFocus();
    window.addEventListener('resize', this.handleFocus);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleFocus);
    ReactDOM.findDOMNode(this.searchInput).firstChild.removeEventListener(
      'focus',
      this.scrollToPoint
    );
  }

  handleFocus() {
    const vh = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );

    if (vh < 800 && !this.focusEnabled) {
      this.focusEnabled = true;
      ReactDOM.findDOMNode(this.searchInput).firstChild.addEventListener(
        'focus',
        this.scrollToPoint
      );
    }
  }

  scrollToPoint() {
    window.scroll({
      top: 120,
      left: 0,
      behavior: 'smooth',
    });
  }

  render() {
    const { searchableContent, fieldsToSearch } = this.props;
    const { collectionsSearchTerm } = this.context;
    const filteredContent = searchableContent.filter(
      createFilter(collectionsSearchTerm, fieldsToSearch)
    );

    return (
      <>
        <SearchInputStyled searchTerm={collectionsSearchTerm}>
          <SearchInput
            className={'search-input'}
            onChange={this.searchUpdated}
            placeholder={'Search Collection'}
            value={collectionsSearchTerm}
            ref={ref => (this.searchInput = ref)}
          />
          <button className={'clear-button'} onClick={this.clearSearch}>
            &times;
          </button>
        </SearchInputStyled>
        {this.props.render(filteredContent)}
      </>
    );
  }
}

export default SearchableContainer;
